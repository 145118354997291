<template>
  <div class="home">
    <GameList :gameType="0" :gameName="''" :hot="-1" :recommend="-1"></GameList>
  </div>
</template>

<script>
import { getIP } from "../api/ipapi.js";
import gameApi from '../api/gameApi.js';
import GameList from '../components/GameList.vue';

export default {
  name: 'HomeView',
  components: {
    GameList,
  },
  data() {
    return {
      gameList: [],
      page: 0,
      isLoading: false,
      pageSize: 20,

    }
  },
  watch: {
    $route() {
      this.getGameList();
    },
  },
  mounted() {
    console.log('HomeView mounted');

    // // Get the 'channel' query parameter from the URL
    // let channel = this.$route.query.ch;
    // console.log('HomeView mounted: ', channel);
    // // this.getGameList();
    // if (channel == "c") {
    //   this.checkIP(channel);
    // }
  },
  methods: {
    checkIP(channel) {
      getIP().then(res => {
        // Check if the response is valid and contains the status 'success'
        if (res.request.readyState === 4 && res.status === 200) {
          if (res.data.status === 'success') {
            // Check the country code for redirection conditions
            if (['SG', 'CN', 'HK', 'MO'].includes(res.data.countryCode)) {
              // If countryCode is SG, CN, MO, or HK, do nothing and exit
              return;
            }
          }
        }
        if (channel == 'c') {
          // Set the interval to refresh the page after 13 seconds if condition fails
          // this.refreshInterval = setInterval(() => {
          //   location.href = `https://fintechfountain.com`;
          // }, 15000);
        }
      }).catch(err => {
        console.error(err);
      });
    },
    getGameList($state) {
      if (!this.isLoading) {
        this.isLoading = true;
        gameApi.getGameList(this.page, this.pageSize, 0, '', -1, -1).then((response) => {
          console.log('response', response);
          if (response.status === 200 && response.data.code == 0) {

            if (response.data.data.items.length == 0) {
              $state.complete();
              return;
            }

            this.gameList = this.gameList.concat(response.data.data.items);
            console.log('gameList: ', this.gameList);
            this.page += 1;
            this.isLoading = false;
            $state.loaded();
          } else {
            console.log('request gameApi.getGameList error', response);
            this.isLoading = false;
            $state.loaded();
          }
        });
      }
    },
    onGameClicked(item) {
      console.log('onGameClicked: ', item);
      this.$router.push({ name: 'game', params: { gameid: item.id } }).catch(err => { err });
    },
    getGameName(str) {
      if (!str) {
        return
      }
      return str.replace(/([a-z0-9])([A-Z0-9])/g, '$1 $2')
    },
  },
}
</script>

<style scoped></style>