<template>
  <div id="app">
    <BYNavBar />
    <router-view />

    <!-- footer -->

    <!-- <div class="bg-primary" style="width:100%">
      <div>Terms & Conditions Privacy</div>
    </div> -->

    <div id="end" class="end bg-primary">
      <router-link to="/terms">Terms of Use</router-link>
      <router-link to="/privacy">Privacy Policy</router-link>
      <!-- <a href="/terms-of-use">Terms of Use</a>
      <a href="/termsofuse">Privacy Policy</a> -->
      <!-- <a href="/static/desktop/html/cookies_policy.html" target="_blank">Cookies</a>
      <a href="/static/desktop/html/contact.html" target="_blank">Contact us</a> -->
    </div>
    <div style="font-size: 12px;" class="mt-2 mb-2">
      <div>
        Copyright © 2025 whirlblend.com
      </div>
      <div>
        All Rights Reserved
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import BYNavBar from '@/components/BYNavBar.vue'

export default {
  name: 'HomeView',
  components: {
    BYNavBar
  },
  data() {
    return {
      routeName: '',
    }
  },
  watch: {
    $route(to) {
      console.log('to: ', to);
      this.routeName = to.name;
    }
  },
  mounted() {
    console.log('HomeView mounted');
    console.log('this.$route: ', this.$route);
    this.routeName = this.$route.name;
  },
}
</script>

<style lang="scss">
@import "./app.scss";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $primary-color;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.end {
  width: 100%;
  height: 40px;
  /* background-color: #002d88; */
  margin-top: 42px;
  text-align: center;
  font-size: 0;
  color: $primary-color;
}

.end>a:not(:last-child) {
  margin-right: 30px;
}

.end a {
  text-decoration: none;
  font-size: 12px;
  color: #ffffff;
  line-height: 40px;
  position: relative;
}

.end>a:not(:last-child):before {
  content: "";
  height: 13px;
  width: 1px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -16px;
}
</style>
