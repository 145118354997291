import axios from 'axios';

const apiIp = axios.create({
    baseURL: 'https://pro.ip-api.com/',
});

const api = axios.create({
    baseURL: 'https://webapi.khculture.com',
});

export function getIP() {
    return apiIp.request({
        url: '/json/?fields=status,message,countryCode&key=MoP2OvRc1pft4uo',
        method: 'GET',
    });
}

export function getChannelFromUrl(url) {
    try {
        let params = new URLSearchParams(url);
        console.log(params.get("channel"));

        let channel = 0
        if (params.get("channel")) {
            channel = params.get("channel")
        }
        return channel
    } catch (error) {
        return 0
    }
}

// 拉取廣告代碼接口
// https://webapi.khculture.com/advert/get_list
// Method: Post
// Content - Type: json
// 參數{
//     key: 關鍵字(可理解為鏈接域名) * 必要
//     name: 名稱(可理解為頁面名稱)
//     position: 位置
//     page: 頁碼
//     size: 條數
// }
// key為必須傳遞參數，其他參數可不傳遞，不傳遞則不作為查詢條件
// 返回數據:正常code=0
// data.items為廣告代碼數據
// {
// key,
// name,
// position,
// code:廣告代碼
// }
export async function getPosterList(key, name, channel = 0) {
    try {
        const res = await getIP();

        // console.log(res.data)
        if (key == "fluentinsaying.com" && name == "financial_goals_students") {
            if (channel == 21 || channel == 24 || channel == 80) {
                // name = "financial_goals_students_channel21"
                name = "financial_goals_students_channel" + channel
            } else if (res.data.countryCode == 'CN' || res.data.countryCode == 'HK' || res.data.countryCode == 'SG' || res.data.countryCode == 'MO') {
                name = "financial_goals_students_ip"
            }
        } else if (key == "fluentinsaying.com" && name == "news-index") {
            if (channel == 24) {
                // name = "financial_goals_students_channel21"
                name = name + "_channel" + channel
            }
        } else if (key == "buzz-way.com" && name == "buzzway_index") {
            if (channel == 24 || channel == 80) {
                // name = "financial_goals_students_channel21"
                name = name + "_channel" + channel
            }
        }

        if (res.request.readyState == 4 && res.status == 200) {
            if (res.data.status == 'success') {
                return api.request(
                    {
                        url: '/adt/get_list',
                        method: 'POST',
                        data: {
                            key: key,
                            name: name,
                            area: res.data.countryCode,
                            channel: channel,
                        }
                    },
                )
            }
        }

        // console.log("getIP error, set area to none")

        return api.request(
            {
                url: '/adt/get_list',
                method: 'POST',
                data: {
                    key: key,
                    name: name,
                    channel: channel,
                    // area: 'none',
                }
            },
        )
    } catch (err) {
        console.log(err)
        return api.request(
            {
                url: '/adt/get_list',
                method: 'POST',
                data: {
                    key: key,
                    name: name,
                    channel: channel,
                    // area: 'none',
                }
            },
        )
    }
}

export function getPosterListDirect(key, name, channel = 0) {
    return api.request(
        {
            url: '/adt/get_list',
            method: 'POST',
            data: {
                key: key,
                name: name,
                channel: channel,
                // area: 'none',
            }
        },
    )
}

function addStyleToAd(adDivRef, postData) {
    adDivRef.style.textAlign = "center";

    // if (postData.name !== "fixed_income"
    //     && postData.name !== "bank_deposit"
    //     && postData.name !== "financial_goals_students"
    //     && postData.name !== "unlocking_home_equity"
    //     && postData.name !== "news-index"
    //     && postData.name !== "news_index_redirct"
    //     && postData.name !== "news_index_redirct_redirct"
    //     && postData.name !== "financial_goals_students_redirect"
    //     && postData.name !== "finance_basics_101"
    //     && postData.name !== "finance_basics_101_direct"
    // ) {
    // return
    // }

    // if (postData.code.indexOf('div-gpt-ad-1680001705548937-0') < 0 && postData.code.indexOf('div-gpt-ad-1683697759306175-0') < 0 && postData.code.indexOf('div-gpt-ad-1680001705146020-0') < 0) {
    //     return
    // }

    var addiv = document.createElement("div")
    addiv.id = "addiv";
    addiv.style.width = "100%";
    addiv.style.height = "25px";
    addiv.style.backgroundColor = "#ff0000";
    addiv.style.color = "#ffffff";
    addiv.style.fontWeight = "bold";
    addiv.style.fontSize = "14px";

    // addiv.style.lineHeight = "25px";
    // addiv.style.position = "absolute";
    // addiv.style.top = "0px";
    // addiv.style.display = "none";
    addiv.style.display = "flex"; // Use flexbox for vertical alignment
    addiv.style.alignItems = "center"; // Align items vertically at the center
    addiv.style.justifyContent = "center"; // Align content horizontally at the center
    addiv.style.position = "absolute";
    addiv.style.top = "0px";

    addiv.textContent = 'Here is the Ad';
    adDivRef.append(addiv);

    var imgElement = document.createElement('img');
    imgElement.src = '../img/finger-down.png';
    imgElement.width = 20;
    imgElement.height = 20;
    imgElement.style.display = "block"; // Ensure image is centered within its parent
    imgElement.style.marginLeft = "4px";
    addiv.appendChild(imgElement);

    adDivRef.style.background = "#f5f5f5";
    adDivRef.style.position = "relative";
    adDivRef.style.paddingTop = "32px";
    adDivRef.style.paddingBottom = "8px";
    adDivRef.style.marginTop = "8px";
    adDivRef.style.marginBottom = "8px";

    const regex = /id='([^']+)'/;
    const match = postData.code.match(regex);

    if (match && match[1]) {
        const idValue = match[1];
        setTimeout(() => {

            // Get the element by its ID
            var element = document.getElementById(idValue);

            // Get the computed style of the element
            var style = window.getComputedStyle(element);

            // Get the value of a specific style property
            var display = style.getPropertyValue("display");

            if (display == 'none') {
                addiv.style.display = "none";
            }

            // console.log(idValue);
            // console.log(display);
        }, 1000);
    } else {
        console.log('ID not found in the HTML string.');
    }
}

export function initPosterEx(postData, that) {
    // 通过 <script> 标签进行分割
    let parts = postData.code.split('<script>');

    console.log("initPosterEx parts:", parts)


    // 处理分割后的结果
    let insstr = parts[0];

    console.log("initPosterEx insstr:", insstr)


    // 获取 script 部分并去掉 </script> 标签
    let scriptContent = parts[1].split('</script>')[0].trim();

    console.log("initPosterEx scriptContent:", scriptContent)

    if (postData.position.indexOf('top') > -1) {
        that.posterTop = insstr
    } else if (postData.position.indexOf('mid') > -1) {
        that.posterMid = insstr
    } else if (postData.position.indexOf('third') > -1) {
        that.posterThird = insstr
    }

    setTimeout(() => {
        var ele = document.createElement("script")
        ele.innerHTML = scriptContent

        if (postData.position.indexOf('top') > -1) {
            if (Array.isArray(that.$refs.posterTop)) {
                that.$refs.posterTop[0].append(ele)
                // addStyleToAd(that.$refs.posterTop[0], postData)
            } else {
                that.$refs.posterTop.append(ele)
                // addStyleToAd(that.$refs.posterTop, postData)
            }
        } else if (postData.position.indexOf('mid') > -1) {
            if (Array.isArray(that.$refs.posterMid)) {
                that.$refs.posterMid[0].append(ele)
                // addStyleToAd(that.$refs.posterMid[0], postData)
            } else {
                that.$refs.posterMid.append(ele)
                // addStyleToAd(that.$refs.posterMid, postData)
            }
        } else if (postData.position.indexOf('third') > -1) {
            if (Array.isArray(that.$refs.posterThird)) {
                that.$refs.posterThird[0].append(ele)
                // addStyleToAd(that.$refs.posterThird[0], postData)
            } else {
                that.$refs.posterThird.append(ele)
                // addStyleToAd(that.$refs.posterThird, postData)
            }
        }
    }, 20);
}

export function initPoster(postData, that) {
    // 检查字符串是否包含 <ins> 标签
    let hasInsElement = postData.code.includes('<ins');
    if (hasInsElement) {
        initPosterEx(postData, that)
        return
    }

    var str1 = ''

    if (postData.position == "long") {
        that.posterLong = postData.code
    } else if (postData.position == "btm_anchor_self") {
        str1 = postData.code

        let startTag = '<script>';
        let endTag = '</script>';

        let startIndex = str1.indexOf(startTag);
        let endIndex = str1.indexOf(endTag, startIndex) + endTag.length;
        let headScript = str1.substring(startIndex, endIndex);

        let posterdiv = str1.replace(headScript, '');
        // var appelem = document.getElementById('app');
        // appelem.innerHTML += posterdiv;
        that.btmAnchorSelf = posterdiv

        headScript = str1.substring(str1.indexOf(startTag) + endTag.length, str1.indexOf(endTag, startIndex));
        const script = document.createElement('script');
        script.textContent = headScript;
        document.body.appendChild(script);
    } else if (postData.position.indexOf('auto') > -1) {
        str1 = postData.code

        let startTag = '<script>';
        let endTag = '</script>';

        let startIndex = str1.indexOf(startTag);
        let headScript = str1.substring(str1.indexOf(startTag) + endTag.length, str1.indexOf(endTag, startIndex));

        const script = document.createElement('script');
        script.textContent = headScript;
        document.head.appendChild(script);

    } else {
        str1 = postData.code

        let startTag = '<script>';
        let endTag = '</script>';

        let startIndex = str1.indexOf(startTag);
        let endIndex = str1.indexOf(endTag, startIndex) + endTag.length;
        let headScript = str1.substring(startIndex, endIndex);

        let strWithoutHeadScript = str1.replace(headScript, '');

        let posterdiv = strWithoutHeadScript.slice(strWithoutHeadScript.indexOf('<div'), strWithoutHeadScript.indexOf(
            ";'>")) + "'>" + strWithoutHeadScript.slice(strWithoutHeadScript.indexOf('</div'))

        // console.log("posterdiv:", posterdiv)

        if (postData.position.indexOf('top') > -1) {
            that.posterTop = posterdiv
        } else if (postData.position.indexOf('mid') > -1) {
            that.posterMid = posterdiv
        } else if (postData.position.indexOf('third') > -1) {
            that.posterThird = posterdiv
        }

        headScript = str1.substring(str1.indexOf(startTag) + endTag.length, str1.indexOf(endTag, startIndex));
        // console.log(headScript)

        const script = document.createElement('script');
        script.textContent = headScript;
        document.head.appendChild(script);

        setTimeout(() => {
            // console.log("strWithoutHeadScript:", strWithoutHeadScript)
            let posterScript = strWithoutHeadScript.substring(strWithoutHeadScript.indexOf('<script>') + 8, strWithoutHeadScript.indexOf('</script>'))
            // console.log(posterScript)
            var ele = document.createElement("script")
            ele.innerHTML = posterScript

            if (postData.position.indexOf('top') > -1) {
                if (Array.isArray(that.$refs.posterTop)) {
                    that.$refs.posterTop[0].append(ele)
                    addStyleToAd(that.$refs.posterTop[0], postData)
                } else {
                    that.$refs.posterTop.append(ele)
                    addStyleToAd(that.$refs.posterTop, postData)
                }
            } else if (postData.position.indexOf('mid') > -1) {
                if (Array.isArray(that.$refs.posterMid)) {
                    that.$refs.posterMid[0].append(ele)
                    addStyleToAd(that.$refs.posterMid[0], postData)
                } else {
                    that.$refs.posterMid.append(ele)
                    addStyleToAd(that.$refs.posterMid, postData)
                }
            } else if (postData.position.indexOf('third') > -1) {
                if (Array.isArray(that.$refs.posterThird)) {
                    that.$refs.posterThird[0].append(ele)
                    addStyleToAd(that.$refs.posterThird[0], postData)
                } else {
                    that.$refs.posterThird.append(ele)
                    addStyleToAd(that.$refs.posterThird, postData)
                }
            }
        }, 20);
    }
}