<template>
  <div>
    <b-card class="rounded-0" style="background: rgba(0, 0, 0, 0.3);;">
      <!-- Background Image with Blur -->
      <div :style="{
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
  backgroundImage: `url(${gameData.icon})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
  filter: 'blur(12px)',
  zIndex: '-1'
      }"></div>
      <b-card-img :src="gameData.icon" :alt="gameData.name"
        style="border: 3px solid #fff;box-shadow: 0 0 1px rgba(0,0,0,0.3); width:150px; height:150px; z-index: 2;"></b-card-img>
      <b-card-body class="px-0" style="padding-bottom: 0; z-index: 4;">
        <b-card-title title-tag="h4" style="color:#fff;font-weight: 800;text-transform: uppercase;">{{
          gameData.name
          }}</b-card-title>
        <b-card-text style="color: rgb(210 210 210); font-weight:500;">
          <b-icon icon="joystick" font-scale="1" variant="primary"></b-icon>
          {{ kFormatter(gameData.playNum) }} played
        </b-card-text>
        <!-- <b-button href="#" variant="primary">Play</b-button> -->
        <b-button class="play-btn" variant="primary" style="width:250px;" @click="playGame()">
          <div class="btn-animation" style="color: #fff;">Play</div>
        </b-button>

        <b-container fluid="md" class="mt-3 px-0" style="max-width: 900px;">
          <div v-if="gameData.desc" class="text-collapse-wrapper" style="color: rgb(210 210 210); font-weight:500;">
            <input id="exp1" class="exp" type="checkbox">
            <div class="text">
              <label class="btn" for="exp1"></label>
              {{ gameData.desc }}
            </div>
          </div>
        </b-container>

      </b-card-body>
    </b-card>
    <div class="mt-3">OTHER GAMES</div>
    <GameList ref="gameList" v-if="gameData.id" :excludeGameId="gameData.id"></GameList>
  </div>
</template>

<script>
import gameApi from '../api/gameApi.js';
import GameList from '../components/GameList.vue';

export default {
  name: "GameDetailView",
  components: {
    GameList
  },
  created() { },
  data() {
    return {
      gameid: 0,
      gameData: {},
      gamePlayData: {},
      dataLoaded: false,
    };
  },
  watch: {
    $route(to) {
      this.gameid = to.params.gameid;
      this.getGameDetail();
      this.$refs.gameList.refresh();
    },
  },
  mounted() {
    console.log('GameDetailView mounted');
    this.gameid = this.$route.params.gameid;
    this.getGameDetail();
  },
  methods: {
    getGameDetail() {
      gameApi.getGameInfo(this.gameid).then((response) => {
        console.log('response', response);
        if (response.status === 200 && response.data.code == 0) {
          this.gameData = response.data.data;
          this.dataLoaded = true;
          console.log('gameData: ', this.gameData);
        } else {
          console.log('request gameApi.getGameDetail error', response);
        }
      });
    },
    playGame() {
      console.log('playGame');
      this.$router.push({ name: 'play', params: { gameid: this.gameid, gamename: this.getGameNameWithDash(this.gameData.name) } });
    },
    getGameName(str) {
      if (!str) {
        return
      }
      return str.replace(/([a-z0-9])([A-Z0-9])/g, '$1 $2')
    },
    getGameNameWithDash(str) {
      // convert string with white space into array
      if (!str) {
        return
      }
      return str.replace(/([a-z0-9])([A-Z0-9])/g, '$1-$2').split(' ').join('-').toLowerCase();
    },
    kFormatter(num) {
      return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num)
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../app.scss";

.btn-primary {
  color: $text-color;
  font-size: x-large;
  font-weight: 500;
  background-color: $primary-color;
  // border-color: #c80000;
}

.btn-animation {
  transform-origin: center;
  animation: 0.75s infinite ease-in-out alternate b-icon-animation-throb;
}

.text-collapse-wrapper {
  display: flex;
  // margin: 50px auto;
  max-width: 900px;
  overflow: hidden;
  /*   resize: horizontal; */
  // border-radius: 8px;
  // padding: 15px;
  // box-shadow: 20px 20px 60px #bebebe,
  //   -20px -20px 60px #ffffff;
}

.text-collapse-wrapper .text {
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
  /* display: flex; */
  /*   display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical; */
  position: relative;
  line-height: 1.3;
  max-height: 2.5em;
  transition: .3s max-height;
}

.text-collapse-wrapper .text::before {
  content: '';
  height: calc(100% - 16px);
  float: right;
}

.text-collapse-wrapper .text::after {
  content: '';
  width: 999vw;
  height: 999vw;
  position: absolute;
  box-shadow: inset calc(100px - 999vw) calc(30px - 999vw) 0 0 #fff;
  margin-left: -100px;
}

.text-collapse-wrapper .btn {
  position: relative;
  float: right;
  clear: both;
  margin-left: 12px;
  font-size: 12px;
  padding: 0 8px;
  background: #0065BD;
  line-height: 12px;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  /* margin-top: -30px; */
}

.text-collapse-wrapper .btn::after {
  content: 'expand'
}

.text-collapse-wrapper .exp {
  display: none;
}

.text-collapse-wrapper .exp:checked+.text {
  max-height: none;
}

.text-collapse-wrapper .exp:checked+.text::after {
  visibility: hidden;
}

.text-collapse-wrapper .exp:checked+.text .btn::before {
  visibility: hidden;
}

.text-collapse-wrapper .exp:checked+.text .btn::after {
  content: 'hide'
}

.text-collapse-wrapper .btn::before {
  content: '...';
  position: absolute;
  left: -5px;
  color: #333;
  transform: translateX(-100%)
}
</style>