var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-card',{staticClass:"rounded-0",staticStyle:{"background":"rgba(0, 0, 0, 0.3)"}},[_c('div',{style:({
      position: 'absolute',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
backgroundImage: `url(${_vm.gameData.icon})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
filter: 'blur(12px)',
zIndex: '-1'
    })}),_c('b-card-img',{staticStyle:{"border":"3px solid #fff","box-shadow":"0 0 1px rgba(0,0,0,0.3)","width":"150px","height":"150px","z-index":"2"},attrs:{"src":_vm.gameData.icon,"alt":_vm.gameData.name}}),_c('b-card-body',{staticClass:"px-0",staticStyle:{"padding-bottom":"0","z-index":"4"}},[_c('b-card-title',{staticStyle:{"color":"#fff","font-weight":"800","text-transform":"uppercase"},attrs:{"title-tag":"h4"}},[_vm._v(_vm._s(_vm.gameData.name))]),_c('b-card-text',{staticStyle:{"color":"rgb(210 210 210)","font-weight":"500"}},[_c('b-icon',{attrs:{"icon":"joystick","font-scale":"1","variant":"primary"}}),_vm._v(" "+_vm._s(_vm.kFormatter(_vm.gameData.playNum))+" played ")],1),_c('b-button',{staticClass:"play-btn",staticStyle:{"width":"250px"},attrs:{"variant":"primary"},on:{"click":function($event){return _vm.playGame()}}},[_c('div',{staticClass:"btn-animation",staticStyle:{"color":"#fff"}},[_vm._v("Play")])]),_c('b-container',{staticClass:"mt-3 px-0",staticStyle:{"max-width":"900px"},attrs:{"fluid":"md"}},[(_vm.gameData.desc)?_c('div',{staticClass:"text-collapse-wrapper",staticStyle:{"color":"rgb(210 210 210)","font-weight":"500"}},[_c('input',{staticClass:"exp",attrs:{"id":"exp1","type":"checkbox"}}),_c('div',{staticClass:"text"},[_c('label',{staticClass:"btn",attrs:{"for":"exp1"}}),_vm._v(" "+_vm._s(_vm.gameData.desc)+" ")])]):_vm._e()])],1)],1),_c('div',{staticClass:"mt-3"},[_vm._v("OTHER GAMES")]),(_vm.gameData.id)?_c('GameList',{ref:"gameList",attrs:{"excludeGameId":_vm.gameData.id}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }